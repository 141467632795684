.header {
  width: 100%;
  min-height: 82px;
  font-family: 'Montserrat', sans-serif;
  background-color: #009345;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 33px;
  padding-bottom: 16px;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    max-width: 275px;
    width: 100%;
    margin-bottom: 9px;
    color: #ffff;
  }

  &__subtitle {
    max-width: 218px;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: lowercase;
    color: #ffff;
  }
}

.btn_close {
  color: #ffff;
  font-size: 36px;
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
  height: 3px;
}

.item_greenback .main_header {
  background: #009345;
}
