.hryvniaRoot {
  display: flex;
}

.color-white {
  background: #83be39;
  color: #83be39;
  fill: #FFFFFF;
}

.color-gray {
  background: #FFFFFF;
  color: #FFFFFF;
  fill: gray;
}

.color-white-disabled {
background: #74a388;
}

.color-gray-disabled {
  background: #74a388;
}
