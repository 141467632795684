:root {
  --headerColour: #343F4B;
  --navigationBackgroundColour:  #FFFFFF;
  --navigationHoverColour: #d4d8dc;
  --pageBackgroundColour:  #F4F4F4;
  --titleTextColour: #47525E;
  --primaryButtonColour: #47525E;
  --secondaryButtonOutlineColour:  #c4c4c4;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
	text-decoration: none;
	box-sizing: border-box;
}

select, input[type="text"], input[type="password"], input[type="email"], textarea, select, button {
	outline: none;
 	-moz-box-sizing:    border-box;
	-webkit-box-sizing: border-box;
	box-sizing:        border-box;
  border: none;
  border-radius: 0;
	resize:none;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	font-family: 'Hind', sans-serif;
	font-weight: 300;
}

.center-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 16px !important;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px !important;
}

.mr-0 {
  margin-right: 0;
}


