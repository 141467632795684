.content {
  background-color: #009345;
  min-height: 100vh;
  color: #fff;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
//   padding-bottom: 70px;
}
.responseMessage {
  margin-top: 16px;
}
.textForUser {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 50vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 283px;
}
.text1row {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 15px;
}
.text2row {
  font-size: 48px;
  line-height: 42px;
  font-weight: 700;
  text-align: center;
}
.content button {
  background-color: #fff;
  color: #219653;
}
