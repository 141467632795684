.button_submit {
  max-width: 335px;
  width: 100%;
  margin-bottom: 13%;
}
.button {
  height: 47px;
  background: #219653;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  cursor: pointer;
}

.button:disabled {
  background: #74a388;
  cursor: unset;
}
