.form {
  // margin-top: 10%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button-container {
    max-width: 222px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0 auto;
    margin-bottom: 33px;
  }
  &__title-cap {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__check-container {
    display: flex;
    justify-content: center;
    margin-bottom: 27px;
  }

  &_disable {
    pointer-events: none;
  }
}

.text_item_title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  max-width: 275px;
  width: 100%;
  margin-bottom: 16px;
  color: #504e58;
}

.link {
  color: #333333;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.underline {
  text-decoration: underline;
  margin: 0 auto;
  text-align: center;
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox + label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked + label::before {
  border-color: #009345;
  background-color: #009345;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.text_item_subtitle {
  color: #263238;
  max-width: 283px;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin-right: unset;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: lowercase;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.input-amount {
  max-width: 283px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 13px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #828282;
  outline: none;
  border: none;
  cursor: pointer;

  &__error {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #ad0101;
    margin: 0 auto;
    text-align: center;
    display: block;
    visibility: hidden;
    margin-bottom: 16px;
  }
}

.visible {
  visibility: visible;
}

.button.default {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  width: 64px;
  height: 64px;
  //background: #ffffff;
  //border: 2px solid #83be39;
  //color: #828282;
  .hrn {
    width: 10px;
    height: 10px;
    display: inline-block;
    //background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBZZBNCoJAHMX9oGXgCULoAlYX6Ah2gaB1C+kCOi0ENy0iaBfRDbxBdIDAGygEraWVIGi/AWewHHj4n3lv3nuOaXRLCDG3bfvStq3HtmiaZsdZqnhLDaZpHiDluAWZZVlXhM5AiNMKLMIwPHNhz5EUedqoF+0THXSXXD4SDy75cKV2lL0QFF1HlyqyX17X9VhHJ0kyoVMAKeMcxBkocTvGcfzSwqqqPog8SJ9tynwDKn7QcYnrHZcZc2b8rb7QQZgzPqUrWJPwjqLI/3ke+We4bRin4ET0CKFQ/BfUsWzqYgX4vwAAAABJRU5ErkJggg==');
  }
  &:active {
    color: #ffffff;
    background: #83be39;
    .hrn {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACXSURBVHgBfdDdCYMwGIXhxPtCJiiOEDqBI2SOUugsHaET1AEK9ro3dgMLHaDiAvEVj5KL6AcPCh5PfozRxBhPaOM8HYLJDR8aBc944A+XCzoUevdqrnLBoNZGS0/zWlqLJPsVjxI1OhzWIH8deVzh5IMeN2vtL20c1BTUdFdrmdtjpX15szc69XQlT1zwRr0VDsmJ27R9BBDOkTfMOZF0AAAAAElFTkSuQmCC');
    }
  }

  span {
    font-size: 23px;
    padding-top: 1px;
  }
}

.button:disabled {
  background: #74a388;
  cursor: initial;
}

.notSelectedButton {
  background: #ffffff;
  border: 2px solid #83be39;
  color: #828282;
  .hrn {
    //width: 10px;
    //height: 10px;
    //display: inline-block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBZZBNCoJAHMX9oGXgCULoAlYX6Ah2gaB1C+kCOi0ENy0iaBfRDbxBdIDAGygEraWVIGi/AWewHHj4n3lv3nuOaXRLCDG3bfvStq3HtmiaZsdZqnhLDaZpHiDluAWZZVlXhM5AiNMKLMIwPHNhz5EUedqoF+0THXSXXD4SDy75cKV2lL0QFF1HlyqyX17X9VhHJ0kyoVMAKeMcxBkocTvGcfzSwqqqPog8SJ9tynwDKn7QcYnrHZcZc2b8rb7QQZgzPqUrWJPwjqLI/3ke+We4bRin4ET0CKFQ/BfUsWzqYgX4vwAAAABJRU5ErkJggg==');
  }
}

.selectedButton {
  color: #ffffff;
  background: #83be39;
  .hrn {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACXSURBVHgBfdDdCYMwGIXhxPtCJiiOEDqBI2SOUugsHaET1AEK9ro3dgMLHaDiAvEVj5KL6AcPCh5PfozRxBhPaOM8HYLJDR8aBc944A+XCzoUevdqrnLBoNZGS0/zWlqLJPsVjxI1OhzWIH8deVzh5IMeN2vtL20c1BTUdFdrmdtjpX15szc69XQlT1zwRr0VDsmJ27R9BBDOkTfMOZF0AAAAAElFTkSuQmCC');
  }
}

.loader {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 50px;
  // height: 54px;
  // &>div{
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   margin: auto;
  // }
}

.loader_not-visible {
  visibility: visible;
}

.form__userlogo {
  margin: 0 auto;
  display: block;
  margin-bottom: 33px;
}
